export enum Permissions {
  create_orgs = 'org.create',
  create_orders = 'order.create',
  view_orgs = 'org.view',
}

export enum CUSTOMER_TYPE {
  admin = 'ADMIN',
  lender = 'LENDER',
  vendor = 'VENDOR',
}

export enum AccessLevelEnum {
  ALL = 'ALL',
  CURRENT = 'CURRENT',
  '*' = '*',
}

interface IPermissionPolicy {
  orgs: {
    allow: AccessLevelEnum[];
  };
}

interface IPermission {
  permission: string;
  policy: IPermissionPolicy;
}

export interface IUserProfile {
  customerType: CUSTOMER_TYPE;
  permissions: IPermission[];
}
