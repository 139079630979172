import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import App from '@/App';
import { SplashScreen } from '@/components/loading-screen';
import { AuthProvider } from '@/context/auth';
import PermissionGuard from '@/context/auth/PermissionGuard';
import RoleGuard from '@/context/auth/RoleGuard';
import { AccessLevelEnum, CUSTOMER_TYPE, Permissions } from '@/context/auth/types';

const Home = lazy(() => import('../pages/Home'));
const Orders = lazy(() => import('../pages/orders/Orders'));
const Lenders = lazy(() => import('../pages/lenders/Lenders'));
const CreateLender = lazy(() => import('../pages/lenders/LenderWizard'));
const CreateOrder = lazy(() => import('../pages/orders/OrderWizard'));
// const Vendors = lazy(() => import('../pages/Vendors'));
// const Reports = lazy(() => import('../pages/Reports'));
const Admin = lazy(() => import('../pages/admin'));
const NotFound = lazy(() => import('../pages/NotFound'));
const OrderDetails = lazy(() => import('../pages/orders/OrderDetails'));
const Forbidden = lazy(() => import('../pages/Forbidden'));

export const AppRoutes = [
  {
    element: (
      <AuthProvider>
        <Suspense fallback={<SplashScreen />}>
          <App />
        </Suspense>
      </AuthProvider>
    ),
    children: [
      { path: '/', element: <Home />, index: true },
      { path: 'orders', element: <Orders /> },
      { path: 'orders/create', element: <CreateOrder /> },
      { path: 'orders/:id', element: <OrderDetails /> },
      {
        path: 'lenders',
        element: (
          <PermissionGuard accessLevel={AccessLevelEnum.ALL} name={Permissions.view_orgs}>
            <Lenders />
          </PermissionGuard>
        ),
      },
      {
        path: 'lenders/create',
        element: (
          <PermissionGuard accessLevel={AccessLevelEnum.ALL} name={Permissions.create_orgs}>
            <CreateLender />
          </PermissionGuard>
        ),
      },
      // { path: 'vendors', element: <Vendors /> },
      // { path: 'reports', element: <Reports /> },
      {
        path: 'admin',
        element: (
          <RoleGuard redirect="/forbidden" role={CUSTOMER_TYPE.admin}>
            <Admin />
          </RoleGuard>
        ),
      },
      { path: '*', element: <NotFound /> },
    ],
  },
  {
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    ),
    children: [{ path: 'forbidden', element: <Forbidden /> }],
  },
];
