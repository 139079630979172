import { AccessLevelEnum, Permissions } from '@/context/auth/types';
import { useUserProfile } from '@/hooks/useUsers';

export const useHasPermission = ({
  accessLevel,
  name,
}: {
  accessLevel: AccessLevelEnum;
  name: Permissions;
}) => {
  const { permissions } = useUserProfile();

  const permission = permissions.find(({ permission }) => permission === name);

  if (!permission) {
    return null;
  }

  return permission.policy.orgs.allow.includes(accessLevel);
};
