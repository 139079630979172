import React from 'react';

import { AccessLevelEnum, Permissions } from '@/context/auth/types';
import { useHasPermission } from '@/hooks/usePermissions';

export default function PermissionGuard({
  accessLevel,
  children,
  name,
}: {
  accessLevel: AccessLevelEnum;
  children: React.ReactNode;
  name: Permissions;
}) {
  const hasPermission = useHasPermission({ accessLevel, name });

  if (hasPermission) {
    return <>{children}</>;
  }
}
